@import "variables";
// includes
@import "includes/helpers";
@import "includes/normalize";
@import "includes/fonts";
@import "../../node_modules/magnific-popup/src/css/main"; 
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    background-color: $black;
    color: $white;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
}

a{
    color: inherit;
    text-decoration: none;
    transition: .3s all;
    @include hover{
        filter: drop-shadow(0 0 rem(5) #fff);
    }
}

.container{
    max-width: rem(800);
}

#page-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    
    &.home{
        &::before{
            content:'';
        }
        #header nav{
            display: block;
        }
        .back-wrap{
            display: none;
        }
        #main{display: none;}
    }
    &.listen{
        position: relative;
        #main{
            flex: 0;
        }
        &::after{
            content:'';
            position: fixed;
            pointer-events: none;
            top: 0;
            bottom: 0;
            left: 50%;
            width: 80vw;
            backdrop-filter: invert(1);
            transform: skew(-10deg);
            
            @media screen and (max-width: 850px){
                position: absolute;
                top: calc(50% -15px);
                bottom: rem(-90);
                left: 0;
                width: 100%;
                transform: skewY(-5deg);
            }
        }
    }
}
#header{
    nav{
        display: none;
    }
    nav a{
        display: block;
        text-align: center;
        width: rem(100);
        max-width: 100%;
        margin: rem(25) auto;
        text-shadow: 0px 0px 3px #FFFFFF;
        text-transform: uppercase;
        font-size: rem(20);
    }
}
h1{
    text-align: center;
    img{
        width: rem(77);
        height: auto;
    }
}

.back-wrap{
    text-align: center; 
    margin: rem(20) 0;
    a{
        display: inline-block;
        filter: drop-shadow(0 0 rem(3) #FFF);
        img,span{
            display: inline-block;
            vertical-align: middle;
        }
        span{
            font-size: rem(20);
            text-transform: uppercase;
            margin-left: rem(10);
        }
        @include hover{
            filter: drop-shadow(0 0 rem(3) #FFF) drop-shadow(0 0 rem(3) #FFF);
        }
    }
}

#main{
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .container{
        max-width: rem(900);
    }
    #watch{
        max-width: rem(600);
        margin: 0 auto;
        .media-responsive{
            margin: 0 auto rem(30);
            background-color: $gray;
        }
    }

    #credits{
        margin-bottom: rem(20);
        .credit-toggle{
            display: flex;
            justify-content: space-between;
            .title{
                font-size: rem(18);
                margin: rem(40) 0;
                text-transform: uppercase;
                font-weight: bold;
                filter: drop-shadow(0 0 rem(2) #fff);
                &.active{
                    text-decoration: underline;
                }
            }
        }
        .credit{
            font-size: rem(12);
            text-transform: uppercase;
            display: none;
            &.active{
                display: block;
            }
            p{
                margin: 0;
            }
        }
    }

    #tour{
        .event{
            &.no-event{
                text-align: center;
                justify-content: center;
                font-size: rem(16);
            }
            display: flex;
            align-items: center;
            letter-spacing: .1em;
            text-transform: uppercase;
            margin-bottom: rem(30);
            text-align: left;
            .event-venue, .event-location{
                flex: 1;
                margin: 0 rem(10);
            }
            .event-date, .event-links{
                flex: .5;
            }

            .event-links{
                a{
                    display: inline-block;
                    text-shadow: 0px 0px 3px #FFFFFF;
                    text-decoration: underline;
                    margin-left: rem(10)
                }
            }
            @media screen and (max-width: 800px){
                text-align: center;
                display: block;
                .btn{
                    margin-top: rem(5);
                }
            }
        }
    }

    #listen{
        display: flex;
        
        @media screen and (max-width: 850px){
            display: block;
        }
        .item{
            flex: 1;
            padding: 0 rem(40);
            display: flex;
            &+.item{
                margin-left: rem(50);
                
                // @media screen and (max-width: 1250px){
                //     background-color: $white;
                //     padding-top: rem(30);
                //     padding-bottom: rem(20);
                //     .dsp{
                //         margin-bottom: 0;
                //     }
                // }
            }
            @media screen and (max-width: 850px){
                margin: 0 auto rem(80);
                &+.item{
                    margin: rem(120) auto rem(30);
                }
            }

            
            >.photo{
                width: rem(200);
                margin-right: rem(30);
                text-align: center;
                @media screen and (max-width: 1250px){
                    margin: 0 auto;
                    width: rem(230);
                }
                img{
                    display: inline-block;
                }
            }
            >div{
                flex: 1;
                &+div{
                    padding: rem(30);
                    
                    @media screen and (max-width: 1440px){
                        padding: rem(15);
                    }
                }
            }

            &:last-child{
                .photo img{
                    filter: invert(1);
                }
                .dsp{
                }
            }
            
            @media screen and (max-width: 800px){
            >div+div>div{padding-right: rem(70);}
            }
            @media screen and (max-width: 1250px){
                display: block;
                width: 100%;
                max-width: rem(300);
                padding: 0 rem(20);
                margin: 0 auto;
                >div+div{
                    margin: rem(30) auto;
                    >div{
                        padding: 0;
                    }
                }
            }
            .dsp{
                >div{
                    display: flex;
                    margin-bottom: rem(30);
                    justify-content: space-between;
                    align-items: center;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .logo{
                        flex: 1;
                        text-align: center;
                        padding-right: rem(10);
                        &.small{
                            img{
                                transform: scale(.8);
                                @media screen and (max-width: 1440px){
                                    transform: scale(.6);
                                }
                                @media screen and (max-width: 1250px){
                                    // transform-origin: center left;
                                    transform: scale(.5);
                                }
                            }
                        }
                        @media screen and (max-width: 650px){
                            text-align: left;
                            &.small img{
                                transform-origin: center left;
                            }
                        }
                        img{
                            width: auto;
                            height: auto;
                            max-width:100%;
                            max-height: rem(30);
                            transform-origin: center;
                            transform: scale(1.5);

                            @media screen and (max-width: 1440px){
                                transform: scale(1.2);
                            }
                            @media screen and (max-width: 1250px){
                                transform: none;
                            }
                        }
                    }
                    a{
                        display: inline-block;
                        width: rem(110);
                        text-align: center;
                        border: 1px solid $white;
                        font-size: rem(15);
                        padding: rem(7) rem(5);
                        background-color: $white;
                        color: $black;
                        text-transform: uppercase;
                        letter-spacing: .01em;
                        @include hover{
                            background: transparent;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}

#footer{
    max-width: 100%;
    .copyright{
        padding: rem(10) 0;
        width: rem(600);
        max-width: 100%;
        margin: 0 auto;
        font-size: rem(8);
        text-transform: uppercase;
        text-align: center;
    }
}

.popup{
    width: rem(400);
    max-width: 90%;
    text-align: center;
    background-color: $white;
    color: $black;
    margin: rem(30) auto;
    padding: rem(35) rem(30);
    font-size: rem(11);
    position: relative;
    opacity: 1;
    .mfp-close{
        transition: .3s all;
        background:transparent;
        border: 2px solid $black;
        width: auto;
        height: auto;
        line-height: 1;
        opacity: 1;
        padding: rem(1) rem(5) 0;
        top: rem(10);
        right: rem(10);
        @include hover{
            background: transparent;
            color: $black;        
            filter: drop-shadow(0 0 rem(3) #000);
        }
    }
    img{
        filter: drop-shadow(0 0 rem(3) #000);
    }
    p{
        transition: .3s filter;
        text-transform: uppercase;
        margin: rem(11) auto;
        span{
            display: inline-block;
        }
        &:first-child{
            font-size: rem(18);
        }
        &:nth-child(2){
            margin: rem(14) 0;
        }
        &.note{
            font-size: rem(8);
            filter: none;
        }
        @include hover{
            filter: drop-shadow(0 0 rem(3) #000);
        }
    }
    a{
        display: block;
        width: 100%;
        text-transform: uppercase;
        max-width: rem(300);
        margin: rem(10) auto;
        border: 2px solid $black;
        text-align: center;
        filter: none;
        padding: rem(11) rem(10) rem(9);
        @include hover{
            filter: drop-shadow(0 0 rem(3) #000);
        }
    }

    input{
        border: none;
        border-bottom: 1px solid $black;
        background: none;
        color: $black;
        &::placeholder{
            color: $black;
            text-transform: uppercase;
        }
    }

    button{
        border: 1px solid $black;
        background: white;
        text-transform: uppercase;
        @include hover{
            background-color: $black;
            color: $white;
        }
    }
}