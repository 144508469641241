//settings
$fa-font-path: "../font";

// Colors //
$black: #000;
$white: #fff;
$primary-color: #f00;
$gray: #656565;

//Fonts
$primary-font: 'AkzidenzGrotesk-Bold', sans-serif;
